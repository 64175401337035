import React from 'react';
import TopHeader from './TopHeader';
import MobileHeader from './MobileHeader';

// Sources


const Header = () => {
  return (
    <>
    <TopHeader/>
    <MobileHeader/>
    </>
  );
}

export default Header;
