import React from 'react';
import Image1 from '../../assets/images/manualrolling/BgDarkblue.webp';
// Sources

const BelowBanner = (props) => {
  return (
    <>
      <div className={`col-12 col-md-3 text-center ${props.Bg} position-relative-bb shadow-ss-1 rounded-20 pt-4 d-none d-lg-block py-xxl-5 py-lg-5`}>
      <img src={Image1} alt="" className='w-100 invisible' />
      <div className="col-12 col-lg-11 mx-auto position-absolute-bb">
          <img src={props.Icon} alt="" className='w-50' />
          <div className="fw-700 fs-ss-24 lh-base p-3 text-uppercase">
            {props.Word1} <span className='text-darkblue'> {props.Word2}  </span>
            </div>
            <div className="fs-ss-services px-3">
            {props.Para} 
            </div>
        </div>
      </div>

      <div className={`col-11 col-md-3 text-center ${props.Bg} shadow-ss-1 rounded-20 py-4 d-block d-lg-none`}>
      <div className="col-12 col-lg-11 mx-auto">
          <img src={props.Icon} alt="" className='w-50' />
          <div className="fw-700 fs-ss-24 lh-base p-3 text-uppercase">
            {props.Word1} <span className='text-darkblue'> {props.Word2}  </span>
            </div>
            <div className="fs-ss-15 px-3">
            {props.Para} 
            </div>
        </div>
      </div>
    </>
  );
}

export default BelowBanner;
