import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom"

// Components
import Header from '../components/layout/Header';
import Iconn1 from '../assets/images/shutteraccessories/Iconn1.webp';
import Iconn2 from '../assets/images/shutteraccessories/Iconn2.webp';
import Icon1 from '../assets/images/shutteraccessories/Icon1.webp';
import Icon2 from '../assets/images/shutteraccessories/Icon2.webp';
import Icon3 from '../assets/images/shutteraccessories/Icon3.webp';
import Icon4 from '../assets/images/shutteraccessories/Icon4.webp';

import BannerImage from '../assets/images/shutteraccessories/Banner.webp';
import Footer from '../components/layout/Footer';
import Img1 from '../assets/images/shutteraccessories/Img1.webp';
import Img2 from '../assets/images/shutteraccessories/Img2.webp';
import ServicesBanner from '../components/ourservices/ServicesBanner';
import ProductDetails from '../components/ourservices/ProductDetails';
import BelowBanner from '../components/ourservices/BelowBanner';
import { CheckCircleFill } from 'react-bootstrap-icons';
// Sources

const ShutterAccessories = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Shutters Accessories | Dar Tariq Company
      </title>
    </Helmet>
    <Header/>

    <ServicesBanner
    BannerImage={BannerImage}
    Word1="Rolling"
    Word2="Shutters"
    Word3="Accessories"
    Para="Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima veniam ut rem corrupti voluptatum voluptatem consequatur quas rerum doloribus porro!"
    />

    <section className="container-fluid px-0" id="website-responsive">

    <div className="py-4 py-lg-5"/>

    <div className="fs-ss-18 fw-500 lh-lg col-11 col-lg-8 mx-auto">
    Shutter accessories come in various types to cater to different shutter styles and individual needs. Here are some common types: 
    </div>

    <div className="py-4 py-lg-5"/>

    <section className="container-fluid">
    <section className="container-lg">
      <div className="row justify-content-center align-items-center gy-4">
        <BelowBanner
        Icon={Icon1}
        Bg="bg-1 me-lg-5"
        Word1="Hardware"
        Word2=""
        Para="This category includes hinges, latches, and pulls. These accessories come in various styles and finishes to complement your shutters seamlessly. "/>

        <BelowBanner
        Icon={Icon2}
        Bg="bg-2"
        Word1="Operational "
        Word2="Accessories"
        Para="These accessories aid in controlling your shutters effectively. Examples include tilt rods, gear mechanisms, and remote controls, offering convenience and ease of use. "/>
              
      </div>
      <div className='py-3 py-lg-4' />
      <div className="row justify-content-center align-items-center gy-4">
        <BelowBanner
        Icon={Icon3}
        Bg="bg-1 me-lg-5"
        Word1="Decorative  "
        Word2="Elements"
        Para="Add personality and charm to your shutters with decorative elements like shutter dogs, louver spacers, and shutter clips, enhancing the aesthetic appeal of your windows or doors. "/>

        <BelowBanner
        Icon={Icon4}
        Bg="bg-2"
        Word1="Security "
        Word2="Accessories"
        Para="Ensure the safety of your home with security accessories such as shutter locks and storm bars, providing added protection against intruders and extreme weather conditions. "/>        
      </div>
    </section>
    </section>

    <div className="py-4 py-lg-5"/>

    <section className="container-fluid">
      <div className="row justify-content-center align-items-center bg-lightblue2">
      <div className="col-11 col-lg-6 d-none d-lg-block px-0">
          <img src={Img1} alt="" className='w-100' />
        </div>
        <div className="col-11 col-lg-6 px-4 px-lg-5 py-5 py-lg-4 text-ss-secondary">
          <div className="px-xxl-5">
          <div className="fs-ss-35 fw-700 lh-sm py-3">
            Shutter Accessories Available
            </div>
            <div className="py-2" />
            <div className="fs-ss-26 fw-700 lh-sm py-3 text-darkblue">
            SHEETS 
            </div>
            <div className="fs-ss-16 fw-400 ">
            Choose from various options, including Saudi (0.07mm, 22 gauge), Dubai, and Japan, catering to different preferences and requirements.
            </div>

            <div className="py-2" />
            <div className="fs-ss-26 fw-700 lh-sm py-3 text-darkblue">
            COLORS 
            </div>
            <div className="fs-ss-16 fw-400 ">
            Select from various colors, including White, Half White, Beige, Light Beige, Dark Beige, Brown, Silver, Gray, and Stone Blue, allowing you to customize your shutters to suit your interior or exterior decor.
            </div>

            <div className="py-2" />
            <div className="fs-ss-26 fw-700 lh-sm py-3 text-darkblue">
            MISCELLANEOUS BRACKET, GUIDE CHANNEL, & GALVANIZED SHEETS 
            </div>
            <div className="fs-ss-16 fw-400 ">
            Additional accessories like brackets, guide channels, and galvanized sheets are available to ensure proper installation and functionality of your shutters.
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 d-block d-lg-none px-0">
          <img src={Img1} alt="" className='w-100' />
        </div>
      </div>
    </section>
    
    <div className="py-4 py-lg-5"/>

    <section className="container-fluid text-center">
    <section className="container-lg">
    <div className="row justify-content-evenly align-items-start gy-4">
    <div className="col-11 col-md-5 text-start d-flex align-items-start">
    <div className="me-4 ">
    <img src={Iconn1} alt="" className='w-icon' />
    </div>
    <div className="">
    <div className="fs-ss-30 fw-700 lh-sm text-darkblue">
      Child Safety    
    </div>
    <div className='fs-ss-16 fw-400 pt-2 pe-lg-5'>
      Learn about our commitment to child safety by clicking the link below and discover how our products contribute to a safer home environment. 
    </div>
    </div>
    </div>

    <div className="col-11 col-md-5 text-start d-flex align-items-start">
    <div className="me-4 ">
    <img src={Iconn2} alt="" className='w-icon' />
    </div>
    <div className="">
    <div className="fs-ss-30 fw-700 lh-sm text-darkblue">
      Product Information    
    </div>
    <div className='fs-ss-16 fw-400 pt-2 pe-lg-5'>
    Introduce the natural beauty of our Nouveau collection into your home. With its simple yet striking design, Nouveau Raspberry adds a vibrant touch to any room it graces. 
    </div>
    </div>
    </div>
    </div>
    </section>
    </section>

    <div className="py-4 py-lg-5"/>

    <section className="container-fluid">
      <div className="row justify-content-center align-items-center">
        <div className="col-11 col-lg-6 px-4 px-lg-5 py-5 py-lg-4 text-ss-secondary">
          <div className="col-lg-10 px-xxl-5 mx-auto">
            <div className="fs-ss-35 fw-700 lh-sm pb-4">
              Product Details
            </div>
            <div className="fs-ss-20 fw-400 lh-lg">
              <div className="pb-4">
              <CheckCircleFill className='text-darkblue me-2'/> Product ID:  DB-ESROM0154
              </div>
              <div className="pb-4">
              <CheckCircleFill className='text-darkblue me-2'/> Fabric Colour: Raspberry
              </div>
              <div className="pb-4">
              <CheckCircleFill className='text-darkblue me-2'/> Material: 100% Cotton
              </div>
              <div className="pb-4">
              <CheckCircleFill className='text-darkblue me-2'/> Lining: Luxury Poly/Cotton
              </div>
              <div className="pb-4">
              <CheckCircleFill className='text-darkblue me-2'/> Headrail: Aluminum Headrail with Steel Bracket
              </div>
              <div className="">
              <CheckCircleFill className='text-darkblue me-2'/> Light Filtering: Yes.
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 ps-lg-5 pe-lg-0 text-end">
          <img src={Img2} alt="" className='w-100' />
        </div>
      </div>
    </section>

    <div className="py-4 py-lg-0"/>
    </section>

    <Footer/>
    </>
  );
}

export default ShutterAccessories;
