import React from 'react';
import {Link} from 'react-router-dom';

// Sources

const CommonBanner = (props) => {
  return (
    <>
        <section id='website-responsive' className="container-fluid bg-common-banner py-lg-5" style={{backgroundImage:`url(${props.BannerImage})`}}>
          <div className="row justify-content-center align-items-center py-5 my-lg-5">
            <div className="col-12 col-lg-9">
              <div className="fs-ss-68 lh-sm fw-700 text-ss-primary">
              <span className="text-lightblue"> {props.Word1} </span> <br className='d-none d-lg-block d-xxl-none' />
              {props.Word2}
              <hr className='hr' />
                <div className="fw-400 fs-ss-rcu text-dark py-2">
                {props.Para}
                </div>
                <Link className='btn-darkblue text-decoration-none fw-500 text-white py-1 px-3 fs-ss-rcu border-0 rounded-5' to="/contact-us">
                    Contact Us
                    </Link>
              </div>
            </div>
          </div>
        </section>
    </>
  );
}

export default CommonBanner;
