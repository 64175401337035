import React from 'react';

// Sources

const BottomFooter = () => {
  return (
    <>
        <section className="container-fluid py-1 lh-lg bg-white fs-ss-14 text-black text-center">
        Copyright © 2024 Dar Tariq Company (DTC). Powered by 
        <a className='text-ss-secondary'  rel="noreferrer" href="https://simplesolutionz.org/" target='_blank'> Simple Solutionz </a>
        </section>
    </>
  );
}

export default BottomFooter;
