import React, { Component } from "react";
import Icon1 from "../../assets/images/home/Icon1.webp";
import Icon2 from "../../assets/images/home/Icon2.webp";
import Icon3 from "../../assets/images/home/Icon3.webp";
import {Link} from 'react-router-dom';

export default class Responsive extends Component {
  render() {
    return (
      <>
      <div id="home-banner" className="home-banner-position-parent text-lg-start text-center">
        <section id='website-responsive' className="container-fluid py-lg-5 py-4 bg-home-banner-1">
          <div className="row justify-content-center align-items-center my-lg-5 py-5">
            <div className="col-11 col-lg-10">
              <div className="fs-ss-58 lh-sm fw-700 text-ss-primary">
                LOOKING FOR <br /> <span className="text-lightblue"> THE PERFECT  </span> <br /> SHUTTERS
                <div className="fw-500 fs-ss-rcu text-dark pt-2">
                We'll take care of everything
                </div>
                <div className='pt-2' />
                <Link className='btn-darkblue text-decoration-none fw-500 text-white py-1 px-3 fs-ss-rcu border-0 rounded-5' to="/contact-us">
                    Contact Us
                    </Link>
              </div>
            </div>
          </div>
        </section>

        <div className="d-none d-lg-flex col-11 col-lg-10 rounded-10 bg-lightblue2 text-center justify-content-center py-2 py-lg-3 text-center shadow-ss-1 fw-400 px-5 home-banner-position-child">
      <div className=" border-2  border-end px-3 px-xl-5">
          <img src={Icon1} alt="" className="w-icons me-lg-3" />
          <span className="fs-ss-rcu text-dark d-block d-lg-inline">
            Residential
          </span>
        </div>
        <div className=" border-2  border-end px-3 px-xl-5">
          <img src={Icon2} alt="" className="w-icons me-lg-3" />
          <span className="fs-ss-rcu text-dark d-block d-lg-inline">
            Commercial
          </span>
        </div>
        <div className=" px-3 px-xl-5">
          <img src={Icon3} alt="" className="w-icons me-lg-3" />
          <span className="fs-ss-rcu text-dark d-block d-lg-inline">
            Ultrasonic Cleaning
          </span>
        </div>
      </div>
      
      </div>
      </>
    );
  }
}