import React from 'react';
import {Link} from 'react-router-dom';
import BottomFooter from './BottomFooter';
import FooterLogo from '../../assets/images/home/FooterLogo.png';
import { EnvelopeFill, Facebook, GeoAltFill, Instagram, Linkedin, TelephoneFill, Tiktok, Twitter, Youtube} from 'react-bootstrap-icons';

// Sources
const Footer = () => {
  return (
    <>
        <section id='website-responsive' className="container-fluid py-3 bg-footer py-5 px-lg-5 text-white">
          <div className="row justify-content-evenly px-xl-2">
            <div className="col-11 col-lg-4">
              <img className='footer-logo' src={FooterLogo} alt="Footer Logo" />
              <div className='pt-4 text-white fs-ss-14'>
               <span className="fw-600"> Dar Tariq Company (DTC) </span> formerly <span className="fw-600"> "Wajood Al Nadir Hardware Trading Company" </span> is an operated business that has been providing high-quality shutter services to homes and Commercial need in Al-Rai, Kuwait for over 21 years.
              </div>
              <div className='pt-3'>
                <a className='text-decoration-none text-white' target='_blank' href="https://www.facebook.com/"> <Facebook className=' me-2 px-1 fs-ss-35 border-social rounded-5'/> </a>
                <a className='text-decoration-none text-white' target='_blank' href="https://twitter.com/"> <Twitter className=' me-2 px-1 fs-ss-35 border-social rounded-5'/> </a>
                <a className='text-decoration-none text-white' target='_blank' href="https://www.linkedin.com/"> <Linkedin className=' me-2 px-1 fs-ss-35 border-social rounded-5'/> </a>
              </div>
            </div>

            <div className="col-11 col-lg-3 d-lg-flex pt-4 pt-lg-0 border-lg-start border-lg-white ">
              <div className='ms-lg-5 '>
                <div className="fs-ss-24 fw-500">
                  Quick Links
                </div>
                <div className="text-white fs-ss-14 pt-2 pt-lg-3">
                <div className="mb-1 mb-lg-2"> <Link className='text-decoration-none text-white' to="/about-us"> About Us </Link> </div>
                <div className="mb-1 mb-lg-2"> <Link className='text-decoration-none text-white' to="/our-services"> Services </Link> </div>
                <div className="mb-1 mb-lg-2"> <Link className='text-decoration-none text-white' to="/our-team"> Our Team </Link> </div>
                <div className="mb-3"> <Link className='text-decoration-none text-white' to="/contact-us"> Contact Us </Link> </div>
                <div className="mb-1 mb-lg-2 fs-ss-20 fw-500"> 
                Timing:
                </div>
                <span className='rounded-5 fw-500 text-dark bg-white px-2 fs-ss-14 border-0'>
                  07:00 AM - 06:00 PM
                </span>
                </div>
              </div>
            </div>

            <div className="col-11 col-lg-3 pt-4 pt-lg-0">
            <div className="fs-ss-24 fw-500">
                  Contact Info
                </div>
              <div className="text-white fs-ss-14 pt-2 pt-lg-3">
               <div className="mb-1 mb-lg-2"> 
               <GeoAltFill className='me-2'/> <a className='text-decoration-none text-white' href="https://www.google.com/maps?ll=29.272874,47.872781&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=390605433084157637" target='_blank'> Al-Rai Industrial Area, Block # 1, Building #410, Behind Al-Baghli Sponge. </a> 
               </div>
               <div className="mb-3"> 
               <EnvelopeFill className='me-2'/> <a className='text-decoration-none text-white' href="mailto:contact@dartariqcompany.com" target='_blank'> contact@dartariqcompany.com </a> 
              </div>
              <div className="mb-1 mb-lg-2 fs-ss-20 fw-500"> 
              Call Us 
              </div>
              <div className="mb-1 mb-lg-2 lh-lg"> 
               <TelephoneFill className='me-2'/> <a className='text-decoration-none text-white' href='tel:(965) 966-77319' target='_blank'> 
                (965) 966-77319 </a> <br />
               <TelephoneFill className='me-2'/> <a className='text-decoration-none text-white' href='tel:(965) 622-29903' target='_blank'> 
                (965) 622-29903 </a> <br />
               <TelephoneFill className='me-2'/> <a className='text-decoration-none text-white' href='tel:(965) 999-56534' target='_blank'> 
                (965) 999-56534 </a>
              </div>
              </div>
            </div>
          </div>
        </section>
        <BottomFooter/>
    </>
  );
}

export default Footer;
