import React from 'react';

// Components
import { CheckCircleFill } from 'react-bootstrap-icons';
// Sources

const ProductDetails = (props) => {

  return (
    <>
    <section className="container-fluid">
      <div className="row justify-content-center align-items-center">
        <div className="col-11 col-lg-6 px-4 px-lg-5 py-5 py-lg-4 text-ss-secondary">
          <div className="col-lg-10 px-xxl-5 mx-auto">
            <div className="fs-ss-35 fw-700 lh-sm pb-4">
              Product Details
            </div>
            <div className="fs-ss-20 fw-400 lh-lg">
              <div className="pb-4">
              <CheckCircleFill className='text-darkblue me-2'/> Product ID: DB-ESROM0154
              </div>
              <div className="pb-4">
              <CheckCircleFill className='text-darkblue me-2'/> Fabric Colour: Raspberry
              </div>
              <div className="pb-4">
              <CheckCircleFill className='text-darkblue me-2'/> Material: 100% Cotton
              </div>
              <div className="pb-4">
              <CheckCircleFill className='text-darkblue me-2'/> Lining: Luxury Poly/Cotton
              </div>
              <div className="pb-4">
              <CheckCircleFill className='text-darkblue me-2'/> Headrail: Aluminium with Steel Brackets
              </div>
              <div className="">
              <CheckCircleFill className='text-darkblue me-2'/> Light Filtering: Yes.
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 ps-lg-5 pe-lg-0 text-end">
          <img src={props.Img1} alt="" className='w-100' />
        </div>
      </div>
    </section>
    </>
  );
}

export default ProductDetails;
