import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
 
const FAQs2 = () => {
  return (
    <>
                  <Accordion className='border-0'>
                      <Accordion.Item eventKey="0">
                          <Accordion.Header>
                              <span className='faqs-heading text-black py-2'>
                              What are the benefits of manual rolling shutters?
                              </span>
                          </Accordion.Header>
                          <Accordion.Body>
                              <span className='faqs-ans'>
                              Manual shutters offer a compelling combination of advantages, including: <br /> <br />
                              <span className="fw-600"> Enhanced security: </span> Deter intruders and vandalism with robust slats that withstand harsh weather and forced entry attempts. Just like their automatic counterparts, they act as a formidable physical barrier. <br /> <br />
                              <span className="fw-600"> Effortless self-reliance: </span> No electricity required! Operate your shutters at your own pace with smooth-gliding mechanisms for effortless control. <br /> <br />
                              <span className="fw-600"> Low maintenance: </span> Durable materials and a straightforward design mean minimal upkeep for years of reliable operation.
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-0  bg-lightblue2'>
                      <Accordion.Item eventKey="0">
                          <Accordion.Header>
                              <span className='faqs-heading text-black py-2'>
                              What are the different types of manual shutters?
                              </span>
                          </Accordion.Header>
                          <Accordion.Body>
                              <span className='faqs-ans'>
                              Manual shutters come in various flavors, each catering to specific needs: <br /> <br />
                              <span className="fw-600"> Solid shutters: </span> Offer maximum security and privacy with opaque slats made from aluminum or steel. Great for protecting valuables and ensuring complete darkness.
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-0'>
                      <Accordion.Item eventKey="0">
                          <Accordion.Header>
                              <span className='faqs-heading text-black py-2'>
                              How do manual shutters work?
                              </span>
                          </Accordion.Header>
                          <Accordion.Body>
                              <span className='faqs-ans'>
                              Operating manual shutters is straightforward! Here’s the gist: <br /><br />
                              <span className="fw-600"> Grab the handle or crank: </span> This is your control center for raising and lowering the shutters. <br /><br />
                              <span className="fw-600"> Pull or crank gently: </span> Depending on the size and spring-assistance (if any), you’ll exert some effort to raise the shutters. <br /><br />
                              <span className="fw-600"> Engage the locking mechanism: </span> Once the shutters are at the desired height, secure them in place with a latch or lock for added security.
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-0  bg-lightblue2'>
                      <Accordion.Item eventKey="0">
                          <Accordion.Header>
                              <span className='faqs-heading text-black py-2'>
                              How much do manual shutters cost?
                              </span>
                          </Accordion.Header>
                          <Accordion.Body>
                              <span className='faqs-ans'>
                              The cost of manual shutters depends on several factors, including: <br/>    <br />
                              <span className="fw-600"> The size and type of shutters: </span> Larger shutters and those made from premium materials like steel will naturally cost more. <br/>  <br />
                              <span className="fw-600"> Spring-assistance: </span> If you opt for spring-assisted shutters, expect a slightly higher price tag for the added convenience. <br/>  <br />
                              <span className="fw-600"> Installation costs: </span> Labor costs can vary depending on the complexity of the installation.
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    
      </>
  );
}

export default FAQs2;
