import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom"

// Components
import Header from '../components/layout/Header';
import Iconn1 from '../assets/images/manualrolling/Iconn1.webp';
import Iconn2 from '../assets/images/manualrolling/Iconn2.webp';
import Icon1 from '../assets/images/manualrolling/Icon1.webp';
import Icon2 from '../assets/images/manualrolling/Icon2.webp';
import Icon3 from '../assets/images/manualrolling/Icon3.webp';
import Icon4 from '../assets/images/manualrolling/Icon4.webp';
import Icon5 from '../assets/images/manualrolling/Icon5.webp';

import BannerImage from '../assets/images/manualrolling/Banner.webp';
import Footer from '../components/layout/Footer';
import Img1 from '../assets/images/manualrolling/Img1.webp';
import ServicesBanner from '../components/ourservices/ServicesBanner';
import ProductDetails from '../components/ourservices/ProductDetails';
import WhatsIncluded from '../components/ourservices/WhatsIncluded';
import BelowBanner from '../components/ourservices/BelowBanner';
// Sources

const ManualShutters = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Manual Shutters | Dar Tariq Company
      </title>
    </Helmet>
    <Header/>

    <ServicesBanner
    BannerImage={BannerImage}
    Word1="Manual"
    Word2="Rolling"
    Word3="Shutters"
    Para="Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima veniam ut rem corrupti voluptatum voluptatem consequatur quas rerum doloribus porro!"
    />

    <section className="container-fluid px-0" id="website-responsive">

    <div className="py-4 py-lg-5"/>

    <div className="fs-ss-18 fw-500 lh-lg col-11 col-lg-8 mx-auto">
    Experience ultimate control and peace of mind with our Manual Rolling Shutters, designed to offer unparalleled protection and convenience. Here's why they stand out: 
    </div>

    <div className="py-4 py-lg-5"/>

    <section className="container-fluid">
    <section className="container-lg">
      <div className="row justify-content-center align-items-center gy-4">
        <BelowBanner
        Icon={Icon3}
        Bg="bg-1 me-lg-5"
        Word1="Built"
        Word2="to Last"
        Para="Crafted from heavy-duty aluminum or steel slats, our shutters defend against harsh weather conditions, vandalism, and attempted forced entry, ensuring long-lasting security for your property. "/>

        <BelowBanner
        Icon={Icon4}
        Bg="bg-2 me-lg-5"
        Word1="Low"
        Word2="Maintenance"
        Para="Enjoy years of reliable operation with minimal upkeep, thanks to our shutters' durable materials and straightforward design. Say goodbye to frequent maintenance hassles and hello to peace of mind. "/>

        <BelowBanner
        Icon={Icon2}
        Bg="bg-1"
        Word1="Manual "
        Word2="Operation"
        Para="Take charge of your security with manual operation, allowing you to raise and lower the shutters at your own pace. Our smooth-gliding mechanisms ensure effortless control, putting safety at your fingertips. "/>                
      </div>
      <div className='py-3 py-lg-4' />
      <div className="row justify-content-center align-items-center gy-4">
        <BelowBanner
        Icon={Icon1}
        Bg="bg-1 me-lg-5"
        Word1="No Electricity"
        Word2="Required"
        Para="Ideal for situations without access to a power source or for those seeking a self-sufficient solution, our manual rolling shutters operate without electricity, providing reliable security whenever needed. "/>

        <BelowBanner
        Icon={Icon5}
        Bg="bg-2"
        Word1="Cost-Effective "
        Word2="Solution"
        Para="Benefit from a secure barrier without additional electric motors or complex installation expenses. Our manual rolling shutters offer a cost-effective solution without compromising quality or security. "/>        
      </div>
    </section>
    </section>

    <div className="py-4 py-lg-5"/>

    <WhatsIncluded
    Icon1={Iconn1}
    Icon2={Iconn2}
    Para={<>
        Each set of our Roller Blinds is carefully packed with fitting instructions, screws, and fixtures, ensuring hassle-free installation.  <br />
    <span className='fst-italic'> Need guidance?  </span> <br />
    Check out our fitting video for step-by-step instructions.
    </>}
    Para1="At DTC, we prioritize safety. Learn more about how we make our products and your home child-safe. "
    Para2={<>Enhance your space with our Nouveau collection, featuring a simple yet striking design that brings the beauty of nature indoors.  <div className="py-1" />
    
    Brighten any room with the vibrant elegance of Nouveau Raspberry. This beautiful bright red blind adds personality and charm to any space it adorns.</>}
    />

    <ProductDetails
    Img1={Img1}
    />

    <div className="py-4 py-lg-0"/>
    </section>

    <Footer/>
    </>
  );
}

export default ManualShutters;
