import React from 'react';
import { Link } from "react-router-dom"
import Navbar from 'react-bootstrap/Navbar';
// import { useLocation } from "react-router-dom";
import HeaderLogo from '../../assets/images/home/Logo.png';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavBarLink2 from '../NavBarLink2';
import NavBarLink from '../NavBarLink';


const MobileHeader = () => {
  // const location = useLocation();

  // const { pathname } = location;  
  // const splitLocation = pathname.split("/");

  return (
    <>
      <Navbar expand="lg" className="bg-lightblue2 px-3 px-lg-5 py-1" id="website-nav">
        <div className='container-fluid'>
            <Navbar.Brand className='navbar-brand'>
              <Link
            className="ms-lg-5"
            to="/"
            >
            <img
                src={HeaderLogo}
                alt="Logo"
                className="header-logo ms-lg-3"
            />
            </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} className='rounded-1 bg-darkblue-light fs-ss-14 px-2 ' style={{border:"none", marginLeft: "70px"}} />
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="start"
            className="navbar-offcanvas"
            >
            <Offcanvas.Header closeButton className='bg-darkblue-light'>

                <Offcanvas.Title className='' id={`offcanvasNavbarLabel-expand-lg`}>
                    <Link
                    className="flex-wrap navbar-brand d-flex align-items-baseline"
                    to="/"
                    >
                    <img
                        src={HeaderLogo}
                        alt="Logo"
                        className=""
                        style={{width: "194px"}}
                    />
                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            {/* <hr /> */}
            <Offcanvas.Body>
            <ul className="navbar-nav ms-auto">
              
              <NavBarLink
              Name="Home"
              Link_To=""
              /><span className='px-md-2'/>

              <NavBarLink
              Name="About Us"
              Link_To="about-us"
              /><span className='px-md-2'/>

              <NavBarLink
              Name="Our Team"
              Link_To="our-team"
              /><span className='px-md-2'/>
              
              <NavBarLink2
              Name="Services"
              Link_To="our-services"
              DropdownMenu={<>
              
              <NavBarLink
              Name="Remote Control Shutters"
              Link_To="automatic-remote-rolling-shutter"
              Border="border-grey pt-2"
              />
              
              <NavBarLink
              Name="Manual Control Shutters"
              Link_To="manual-rolling-shutter"
              Border="border-grey pt-2"
              />
              
              <NavBarLink
              Name="Shutters Accessories"
              Link_To="shutter-accessories"
              Border="border-grey pt-2"
              />
              </>}
              /><span className='px-md-2'/>

              <NavBarLink
              Name="Contact Us"
              Link_To="contact-us"
              /><span className='px-md-2'/>
              
              <li>
                <div className=''>
                  <Link className="" to="/">
                    <button className='btn-darkblue text-white py-1 px-3 fs-ss-16 border-0 rounded-5 mt-3 mt-lg-0'>
                      Free Quotation
                      </button>
                      </Link>
                      </div>
                      </li>
                      </ul>

            </Offcanvas.Body>
            </Navbar.Offcanvas>
            </div>        
            </Navbar>
    </>
  )
}

export default MobileHeader