import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/Banner.webp';
import {Link} from "react-router-dom";
import { CupFill, EnvelopeFill, Facebook, GeoAltFill, Linkedin, Printer, PrinterFill, TelephoneFill, Twitter, Whatsapp } from 'react-bootstrap-icons';
import InquiryForm from '../components/InquiryForm';

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
        Contact Us - Dar Tariq Company
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid contact-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="py-4">
          <div className='text-center'>
          <div className='fw-700 lh-sm text-white text-uppercase fs-ss-40'>
           Contact Us
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <div className="py-4 py-lg-5"/>

    <section id='website-responsive' className='container-lg pe-md-5 text-ss-secondary'>
                <div className='py-md-0 py-3'>
                <div className="">
                    <div className='row justify-content-center text-center'>
                        <div className='col-12 col-xl-4 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <TelephoneFill className='text-darkblue' size={40} />
                                <div className='fs-ss-26 mt-3 mb-md-3'>
                                    <div className='fw-600' >Phone</div>
                                </div>
                            </div>
                            <div className='my-2'>

                                    <div className=''><a className="font-pri font-para hover-sec-head text-decoration-none text-ss-secondary" target="_blank" href="tel:+96596677319">  (965) 966-77319 </a></div>

                                    <div className='py-2'><a className="font-pri font-para hover-sec-head text-decoration-none text-ss-secondary" target="_blank" href="tel:+96562229903">  (965) 622-29903 </a></div>

                                    <div className=''><a className="font-pri font-para hover-sec-head text-decoration-none text-ss-secondary" target="_blank" href="tel:+96599956534">  (965) 999-56534 </a></div>
                            </div>
                            
                        </div>

                        <div className='col-12 col-xl-4 mb-3 border-end border-2'>
                            <div className='my-2'>
                                <GeoAltFill className='text-darkblue' size={40} />
                                <div className='fs-ss-26 mt-3 mb-md-3'>
                                    <div className='fw-600' > Address </div>
                                </div>
                            </div>
                            <div className='my-2'>
                                    <div className=''><a className="font-pri font-para hover-sec-head text-decoration-none text-ss-secondary" target="_blank" href="https://www.google.com/maps?ll=29.272874,47.872781&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=390605433084157637">   Al-Rai Industrial Area, Block # 1, Building #410, Behind Al-Baghli Sponge. </a></div>
                            </div>
                            
                        </div>


                        <div className='col-12 col-xl-4 mb-3'>
                            <div className='my-2'>
                                <EnvelopeFill className='text-darkblue' size={40} />
                                <div className='fs-ss-26 mt-3 mb-md-3'>
                                    <div className='fw-600' >Email</div>
                                </div>
                            </div>
                            <div className='my-2'>
                                    <div className=''><a className="font-pri font-para hover-sec-head text-decoration-none text-ss-secondary" target="_blank" href="mailto: contact@dartariqcompany.com">  contact@dartariqcompany.com </a></div>
                            </div>
                            
                        </div>

                    </div>
                </div>
                </div>
            </section>

            <div className="py-4 py-lg-5"/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center align-items-center bg-darkblue rounded-start rounded-end2">
      <div className="col-12 col-lg-5 px-0 d-block d-lg-none bg-lightblue2">
        <InquiryForm/>
        </div>
        <div className="col-12 col-lg-5 px-lg-5 py-lg-4 py-5 px-4 ms-lg-auto">
          <div className=''>
            <div className='fs-ss-26 fw-700 lh-sm text-white '>
              Operating Hours
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-500 lh-para'>
            <table className="table">
              <tbody>
                <tr>
                  <td classname='pt-5'> Monday: </td>
                  <td className='text-end'> 8:00 AM - 5:00 PM </td>
                </tr>

                <tr>
                  <td classname='pt-5'> Tuesday: </td>
                  <td className='text-end'> 8:00 AM - 5:00 PM </td>
                </tr>

                <tr>
                  <td classname='pt-5'> Wednesday: </td>
                  <td className='text-end'> 8:00 AM - 5:00 PM </td>
                </tr>

                <tr>
                  <td classname='pt-5'> Thursday: </td>
                  <td className='text-end'> 8:00 AM - 5:00 PM </td>
                </tr>

                <tr>
                  <td classname='pt-5'> Friday: </td>
                  <td className='text-end'> 8:00 AM - 5:00 PM </td>
                </tr>

                <tr>
                  <td classname='pt-5'> Saturday: </td>
                  <td className='text-end'> Close </td>
                </tr>

                <tr>
                  <td classname='pt-5'> Sunday: </td>
                  <td className='text-end'> Close </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
        <div className="col-11 col-lg-5 px-0 d-none rounded-end d-lg-block bg-lightblue2 ms-auto ">
        <InquiryForm/>
        </div>
      </div>
    </section>

    <div className="py-4 py-lg-5"/>

    <div className='border-0 z-back-page' >
      <iframe className='w-100 shadow' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3480.3218247004725!2d47.870205774536785!3d29.272878554716236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf919a9ec3fe7d%3A0x56bb59266519ec5!2sal%20rai%20industrial%20area!5e0!3m2!1sen!2s!4v1713165556984!5m2!1sen!2s" height="500"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>

    <div className="py-4 py-lg-5"/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 lh-sm'>
              Connect <span className='text-darkblue'> With Us </span>
              </div>
              <div className='pt-4'/>
              <div className="text-center">
              <a href="https://www.facebook.com/" className='text-darkblue fs-ss-48' target='_blank'>
              <Facebook/>
              </a> 
              <span className='px-4' />
              <a href="https://twitter.com/" className='text-darkblue fs-ss-48' target='_blank'>
              <Twitter/>
              </a> 
              <span className='px-4' />
              <a href="https://linkedin.com/" className='text-darkblue fs-ss-48' target='_blank'>
              <Linkedin/>
              </a>               
              </div>
            </div>
        </div>
      </div>
    </section>
          
    <div className="py-4 py-lg-5"/>

    <Footer/>
    </>
  );
}

export default ContactUs;
