import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
 
const FAQs1 = () => {
  return (
    <>
                  <Accordion className='border-0 '>
                      <Accordion.Item eventKey="0">
                          <Accordion.Header>
                              <span className='faqs-heading text-black py-2'>
                              How are automatic shutters controlled?
                              </span>
                          </Accordion.Header>
                          <Accordion.Body>
                              <span className='faqs-ans'>
                              There are several control options for automatic shutters: <br /><br />
                              <span className='fw-600'> Remote control: </span> Operate your shutters from a distance with a dedicated remote control. <br /><br />
                              <span className='fw-600'> Timer: </span> Schedule your shutters to open and close automatically at specific times, creating a pre-programmed routine for added convenience. <br /><br />
                              <span className='fw-600'> Smartphone app: </span> Enjoy ultimate convenience by controlling your shutters through a smartphone app, even when you’re away from home. <br /><br />
                              <span className='fw-600'> Wall switch: </span> Control your shutters with a simple wall switch for a traditional touch.
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-0  bg-lightblue2'>
                      <Accordion.Item eventKey="0">
                          <Accordion.Header>
                              <span className='faqs-heading text-black py-2'>
                              Are automatic shutters difficult to maintain?
                              </span>
                          </Accordion.Header>
                          <Accordion.Body>
                              <span className='faqs-ans'>
                              Automatic shutters are generally low-maintenance. Regular cleaning with a soft brush and soapy water is sufficient. The motor and other components may require occasional professional servicing, depending on the manufacturer’s recommendations.
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-0 '>
                      <Accordion.Item eventKey="0">
                          <Accordion.Header>
                              <span className='faqs-heading text-black py-2'>
                              Do automatic shutters require electricity?
                              </span>
                          </Accordion.Header>
                          <Accordion.Body>
                              <span className='faqs-ans'>
                              Yes, automatic shutters require electricity to operate the motor. However, some models have backup battery systems in case of power outages.
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-0  bg-lightblue2'>
                      <Accordion.Item eventKey="0">
                          <Accordion.Header>
                              <span className='faqs-heading text-black py-2'>
                              How much do automatic shutters cost?
                              </span>
                          </Accordion.Header>
                          <Accordion.Body>
                              <span className='faqs-ans'>
                              The cost of automatic shutters depends on several factors, including: <br /> <br />
                              <span className="fw-600"> The size and type of shutters: </span> Larger shutters and those made from premium materials like steel will naturally cost more. <br /> <br />
                              <span className="fw-600"> The control system: </span> More advanced control options like smartphone apps may increase the price. <br /> <br />
                              <span className="fw-600"> Installation costs: </span> Labor costs can vary depending on the complexity of the installation.
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    
      </>
  );
}

export default FAQs1;
