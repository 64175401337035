import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FsLightbox from "fslightbox-react";
import Img1 from '../../assets/images/gallery/1.webp';
import Img2 from '../../assets/images/gallery/2.webp';
import Img3 from '../../assets/images/gallery/3.webp';
import Img4 from '../../assets/images/gallery/4.webp';
import Img5 from '../../assets/images/gallery/5.webp';
import Img6 from '../../assets/images/gallery/6.webp';



const PortfolioHeader = () => {

  const [toggler, setToggler] = useState(false);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });
  
  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    });
  }
  return (
    <>
    <div id='website-responsive' className='text-center '>
    <Tabs>
    <TabList className="d-block d-lg-flex justify-content-center fs-ss-projects fw-500 pt-4 px-0">
      <Tab> All </Tab>
      <span className='px-lg-4'> </span>
      <Tab> Manual Shutters </Tab>
      <span className='px-lg-4'> </span>
      <Tab> Remote Shutters </Tab>
    </TabList>

    <TabPanel>
    <div className="row justify-content-center gy-3 gy-lg-4">
            <div className='pointer-clickable col-6 col-lg-4' style={{pointer:"zoomIn"}} onClick={() => openLightboxOnSlide(1)}>
              <img className='w-100' src={Img1} alt=""  />
            </div>
            <div onClick={() => openLightboxOnSlide(2)} className='col-6 col-lg-4 pointer-clickable'>
              <img className='w-100' src={Img2} alt="" />
            </div>
            <div className='pointer-clickable col-6 col-lg-4' onClick={() => openLightboxOnSlide(3)}>
              <img className='w-100' src={Img3} alt="" />
            </div>
            <div className='pointer-clickable col-6 col-lg-4' onClick={() => openLightboxOnSlide(4)}>
              <img className='w-100' src={Img4} alt="" />
            </div>
            <div onClick={() => openLightboxOnSlide(5)} className='col-6 col-lg-4 pointer-clickable' >
              <img className='w-100' src={Img5} alt="" />
            </div>
            <div className='pointer-clickable col-6 col-lg-4' onClick={() => openLightboxOnSlide(6)}>
              <img className='w-100' src={Img6} alt="" />
            </div>
          </div>
			<FsLightbox
				toggler={lightboxController.toggler}
				sources={[
					Img1,
          Img2,
          Img3,
          Img4,
          Img5,
          Img6,
				]}
				slide={lightboxController.slide}
			/>
    </TabPanel>

    <TabPanel>
    <div className="row justify-content-center gy-3 gy-lg-4">
            <div className='pointer-clickable col-6 col-lg-4' style={{pointer:"zoomIn"}} onClick={() => openLightboxOnSlide(1)}>
              <img className='w-100' src={Img6} alt=""  />
            </div>
            <div onClick={() => openLightboxOnSlide(2)} className='col-6 col-lg-4 pointer-clickable'>
              <img className='w-100' src={Img5} alt="" />
            </div>
            <div className='pointer-clickable col-6 col-lg-4' onClick={() => openLightboxOnSlide(3)}>
              <img className='w-100' src={Img4} alt="" />
            </div>
            <div className='pointer-clickable col-6 col-lg-4' onClick={() => openLightboxOnSlide(4)}>
              <img className='w-100' src={Img3} alt="" />
            </div>
            <div onClick={() => openLightboxOnSlide(5)} className='col-6 col-lg-4 pointer-clickable' >
              <img className='w-100' src={Img2} alt="" />
            </div>
            <div className='pointer-clickable col-6 col-lg-4' onClick={() => openLightboxOnSlide(6)}>
              <img className='w-100' src={Img1} alt="" />
            </div>
          </div>
			<FsLightbox
				toggler={lightboxController.toggler}
				sources={[
					Img1,
          Img2,
          Img3,
          Img4,
          Img5,
          Img6,
				]}
				slide={lightboxController.slide}
			/>
    </TabPanel>

    <TabPanel>
    <div className="row justify-content-center gy-3 gy-lg-4">
            <div className='pointer-clickable col-6 col-lg-4' style={{pointer:"zoomIn"}} onClick={() => openLightboxOnSlide(1)}>
              <img className='w-100' src={Img4} alt=""  />
            </div>
            <div onClick={() => openLightboxOnSlide(2)} className='col-6 col-lg-4 pointer-clickable'>
              <img className='w-100' src={Img5} alt="" />
            </div>
            <div className='pointer-clickable col-6 col-lg-4' onClick={() => openLightboxOnSlide(3)}>
              <img className='w-100' src={Img6} alt="" />
            </div>
            <div className='pointer-clickable col-6 col-lg-4' onClick={() => openLightboxOnSlide(4)}>
              <img className='w-100' src={Img1} alt="" />
            </div>
            <div onClick={() => openLightboxOnSlide(5)} className='col-6 col-lg-4 pointer-clickable' >
              <img className='w-100' src={Img2} alt="" />
            </div>
            <div className='pointer-clickable col-6 col-lg-4' onClick={() => openLightboxOnSlide(6)}>
              <img className='w-100' src={Img3} alt="" />
            </div>
          </div>
			<FsLightbox
				toggler={lightboxController.toggler}
				sources={[
					Img1,
          Img2,
          Img3,
          Img4,
          Img5,
          Img6,
				]}
				slide={lightboxController.slide}
			/>
    </TabPanel>
  </Tabs>  
    </div>
    </>
  )
}

export default PortfolioHeader