import React from 'react';
import { ClockHistory, PhoneFill, TelephoneFill } from 'react-bootstrap-icons';
import EnglishImg from '../../assets/images/home/English.png';

// Sources
const TopHeader = () => {
  
  return (
    <>
        <div className='container-fluid text-white bg-darkblue fs-ss-top py-2 px-3 text-lg-start text-center'>
            <div className='col-11 col-lg-10 d-block d-lg-flex fw-400 mx-auto'>
            <div className="rounded-0 border-0 pb-2 pb-lg-0">
            <div id="google_translate_element"></div>
                </div>             
                <div className="ms-auto">
               <TelephoneFill className='me-2'/> Phone: <a className='text-decoration-none text-white border-white border-1 border-end me-2 pe-1' href="tel:(965) 966-77319"> (965) 966-77319  </a> <a className='text-decoration-none text-white' href="tel:(965) 622-29903"> (965) 622-29903 </a>
                </div>
                <div className="px-2 px-lg-4 pt-1 pt-lg-0" />
                <div className="">
               <ClockHistory className='me-2'/> Opening Time: 07:00 - 18:00
                </div>
            </div>
        </div>
        </>
    );
}

export default TopHeader;
