import React from 'react';
import {Routes, Route} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import '../src/assets/css/style.css';
import "aos/dist/aos.css"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Pages
import Home from './pages/Home'
import Error404 from './pages/Error404';
import AboutUs from './pages/AboutUs';
import OurServices from './pages/OurServices';
import OurTeam from './pages/OurTeam';
import AutomaticRemote from './pages/AutomaticRemote';
import ManualShutters from './pages/ManualShutters';
import ShutterAccessories from './pages/ShutterAccessories';
import ContactUs from './pages/ContactUs';



const App = () => {
  return (
    <>
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about-us" element={<AboutUs/>} />
        <Route path="/our-services" element={<OurServices/>} />
        <Route path="/automatic-remote-rolling-shutter" element={<AutomaticRemote/>} />
        <Route path="/manual-rolling-shutter" element={<ManualShutters/>} />
        <Route path="/shutter-accessories" element={<ShutterAccessories/>} />
        <Route path="/our-team" element={<OurTeam/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="*" element={<Error404/>} />
        </Routes>
        <ToastContainer
              position="bottom-left"
              autoClose={5000}
          />
    </>
  );
}

export default App;
