import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom"

// Components
import Header from '../components/layout/Header';
import Icon1 from '../assets/images/automaticremote/Icon1.webp';
import Icon2 from '../assets/images/automaticremote/Icon2.webp';
import BannerImage from '../assets/images/automaticremote/Banner.webp';
import Footer from '../components/layout/Footer';
import Img1 from '../assets/images/automaticremote/Img1.webp';
import Img2 from '../assets/images/automaticremote/Img2.webp';
import { CheckCircleFill } from 'react-bootstrap-icons';
import ServicesBanner from '../components/ourservices/ServicesBanner';
import ProductDetails from '../components/ourservices/ProductDetails';
import WhatsIncluded from '../components/ourservices/WhatsIncluded';
// Sources

const AutomaticRemote = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Automatic or Remote Rolling Shutter | Dar Tariq Company
      </title>
    </Helmet>
    <Header/>

    <ServicesBanner
    BannerImage={BannerImage}
    Word1="Automatic"
    Word2="or Remote"
    Word3="Rolling Shutters"
    Para="Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima veniam ut rem corrupti voluptatum voluptatem consequatur quas rerum doloribus porro!"
    />

    <section className="container-fluid px-0" id="website-responsive">

    <div className="py-4 py-lg-5"/>

    <section className="container-lg">
      <div className="row justify-content-center align-items-center gy-5">
      <div className="col-12 col-lg-4 text-center d-none d-lg-block">
          <img src={Img2} alt="" className='w-img2' />
        </div>
        <div className="col-11 col-lg-8 px-3 px-lg-5 py-lg-4 text-ss-secondary">
          <div className="px-xxl-5">
            <div className="fs-ss-18 fw-500 lh-lg">
            Automatic or Remote Rolling Shutters aren't just regular doors or windows. They're about security and convenience. These shutters are precise and can fit any opening size. They offer top-notch safety against fire and theft. 
            <div className='py-3' />
            Our rolling shutters are made from solid aluminum strips. They are durable and secure. With coiled springs, they operate smoothly and effortlessly, ensuring security. At Dar Tariq Company, we offer rolling or folding shutter doors in various types. They cater to specific needs based on materials and applications. We've covered you for your home, business, commercial, or industrial space. 
            <div className='py-3' />
            Our shutters are low-maintenance and need minimal attention. Motorized options make operations even more accessible, ensuring customer satisfaction. 
            </div>
            <div className="pt-4">
            <Link className="" to="/contact-us">
              <button className='btn-darkblue text-white py-1 px-3 fs-ss-16 border-0 rounded-5 mt-3 mt-lg-0'>
                Contact Us
              </button>
            </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 d-block d-lg-none text-center">
          <img src={Img2} alt="" className='w-img2' />
        </div>
      </div>
    </section>

    <div className="py-4 py-lg-5"/>

    <WhatsIncluded
    Icon1={Icon1}
    Icon2={Icon2}
    Para={<>
        When you receive our Roller Blinds, you'll find them carefully packed with fitting instructions, screws, and fixtures <br />
    <span className='fst-italic'> Need help with installation? </span> <br />
    Check out our fitting video for step-by-step guidance before you order.
    </>}
    Para1=" At Dar Tariq Company, safety is paramount. Learn more about how our products are designed to keep your home child-safe. "
    Para2={<>
    Introducing our Nouveau collection, where simplicity meets perfection to bring nature into your home. 
    Transform any room with the vibrant elegance of Nouveau Raspberry. <div className="py-1" />
    This beautiful bright red blind adds color and personality to any space it graces.</>}
    />

    <ProductDetails
    Img1={Img1}
    />

    <div className="py-4 py-lg-0"/>
    </section>

    <Footer/>
    </>
  );
}

export default AutomaticRemote;
