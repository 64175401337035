import React from 'react';

const ServicesBanner = (props) => {

  return (
    <>
    <section id='website-banner' className="container-fluid bg-common-banner py-lg-5" style={{backgroundImage:`url(${props.BannerImage})`}}>
          <div className="row justify-content-start align-items-center py-5 my-lg-5">
            <div className="col-11 col-lg-6 ps-lg-5 ms-xl-5 py-xl-5">
              <div className="fs-ss-40 lh-sm fw-00 text-white ">
              <span className="text-lightblue"> {props.Word1} </span> {props.Word2}
              <br className='' /> <span className="fs-ss-56 fw-700"> {props.Word3} </span>
              <div className="fw-400 fs-ss-16 text-white pt-4 col-7 col-lg-9 col-xxl-8">
              {props.Para}
              </div>
              </div>
            </div>
          </div>
        </section>
    </>
  );
}

export default ServicesBanner;
