import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom"

// Components
import Header from '../components/layout/Header';
import CommonBanner from '../components/home/CommonBanner';
import Icon1 from '../assets/images/aboutus/Icon1.webp';
import Icon2 from '../assets/images/aboutus/Icon2.webp';
import Icon3 from '../assets/images/aboutus/Icon3.webp';
import Icon4 from '../assets/images/aboutus/Icon4.webp';
import Arrow from '../assets/images/aboutus/Arrow.webp';
import ReachUs from '../assets/images/home/ReachUs.svg';
import Video from '../assets/images/aboutus/Video.mp4';
import BannerImage from '../assets/images/aboutus/Banner.webp';
import Testimonials from '../components/home/Testimonials';
import Footer from '../components/layout/Footer';
import Img1 from '../assets/images/aboutus/Img1.webp';
import PortfolioHeader from '../components/portfolio/PortfolioHeader';
import FAQs1 from '../components/FAQs1';
import FAQs2 from '../components/FAQs2';
import CTA from '../components/CTA';
// Sources

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      About Us | Dar Tariq Company
      </title>
    </Helmet>
    <Header/>
    <CommonBanner
    BannerImage={BannerImage}
    Word1="ABOUT"
    Word2="US"
    Para={<>
    Transforming Spaces, <br className='d-block d-md-none' /> Securing Futures
    </>}
    />

    <section className="container-fluid px-0" id="website-responsive">

    <section className="py-0 py-lg-5 my-lg-2"/>

    <section className="container-lg px-xl-5">
      <div className="row justify-content-center align-items-center bg-darkblue">
      <div className="col-11 col-lg-6 d-none d-lg-block px-0">
          <img src={Img1} alt="" className='w-100' />
        </div>
        <div className="col-11 col-lg-6 px-4 px-lg-5 py-5 py-lg-4 text-white">
          <div className="px-xxl-5">
            <div className="fs-ss-14 fw-400">
              Dar Tariq Company 
            </div>
            <div className="fs-ss-35 fw-700 lh-sm py-3">
            21+ Years of Experience in Shutters Business
            </div>
            <div className="fs-ss-16 fw-400 ">
            Dar Tariq Company (DTC) formerly “Wajood Al Nadir Hardware Trading Company” is an operated business that has been providing high-quality shutter services to homes and Commercial need in Al-Rai, Kuwait for over 21 years. We are committed to providing our customers with the best possible services and products at competitive prices.
            <div className="py-2" />
            One of the principal assets of our company is the ability to be able to offer numerous products from a wide range of colors. This allows us to supply the most suitable product for your requirements, based on quality, budget and product performance.          
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 d-block d-lg-none px-0">
          <img src={Img1} alt="" className='w-100' />
        </div>
      </div>
    </section>

    <div className="py-4 py-lg-5"/>

    <section className="container-lg text-center">
    <div className='pb-4 pb-lg-5 text-center'>
    <div className="fs-ss-35 fw-700 lh-sm text-darkblue">
      Our Features
    </div>
    <div className='fs-ss-18 fw-500 pt-2'>
      <span className='fw-600'> DTC </span> is offering a wide range of feature services. <br className='d-none d-lg-block' /> Those are as follows:
    </div>
    </div>
    <div className="row justify-content-center align-items-center gy-4">
    <div className="col-5 col-md-2">
      <img src={Icon1} alt="" className='w-100' />
      <div className="lh-sm fw-700 fs-ss-20 pt-3">
        FAST <br />
       <span className='text-darkblue'> SERVICE </span>
      </div>
      </div>
      <div className="col-5 col-md-1 d-none d-md-block">
      <img src={Arrow} alt="" className='w-50 mb-lg-5' />
      </div>
      <div className="col-5 col-md-2">
      <img src={Icon2} alt="" className='w-100' />
      <div className="lh-sm fw-700 fs-ss-20 pt-3">
        SAFE <br />
       <span className='text-darkblue'> DELIVERY </span>
      </div>      
      </div>
      <div className="col-5 col-md-1 d-none d-md-block">
      <img src={Arrow} alt="" className='w-50 mb-lg-5' />
      </div>
      <div className="col-5 col-md-2">
      <img src={Icon3} alt="" className='w-100' />
      <div className="lh-sm fw-700 fs-ss-20 pt-3">
        24/7 <br />
       <span className='text-darkblue'> SUPPORT </span>
      </div>      
      </div>
      <div className="col-5 col-md-1 d-none d-md-block">
      <img src={Arrow} alt="" className='w-50 mb-lg-5' />
      </div>
      <div className="col-5 col-md-2">
      <img src={Icon4} alt="" className='w-100' />
      <div className="lh-sm fw-700 fs-ss-20 pt-3">
        BIG <br />
       <span className='text-darkblue'> TEAM </span>
      </div>      
      </div>
    </div>
    </section>

    <div className="py-4 py-lg-5"/>
    
    <section className="container-fluid bg-lightblue2 py-5 text-ss-primary">
    <section className="container-lg text-center">
    <div className='text-center'>
    <div className="fs-ss-35 fw-700 lh-sm text-darkblue">
      Want to see how it works
    </div>
    <div className='fs-ss-18 fw-400 pt-2'>
      Watch this quick demo
    </div>
    <div className="col-lg-7 bg-grey-2 px-2 mt-4 rounded-3 shadow-ss-1 btn-black mx-auto pt-2 bg-darkblue">
      <video className='w-100 rounded-3' src={Video} autoPlay={true} loop="true" muted controls />
    </div>    
    </div>
    </section>
    </section>

    <section className="py-4 py-lg-5"/>
    
    <section className="container-lg text-ss-primary ">
    <div className="text-center pb-4 pb-lg-5">
    <div className="fs-ss-35 fw-700 lh-sm">
      Frequently Asked Questions
    </div>
    <div className='fs-ss-18 fw-400 pt-2 text-center'>
      There are certain FAQs….Please find:
    </div>
    </div>
    <div className="row justify-content-center gy-4">
      <div className="col-11 col-lg-5">
        <div className='bg-darkblue fs-ss-30 text-white fw-500 text-center py-2 py-lg-4'>
          Shutter Automatic
        </div>
        <FAQs1/>
      </div>
      <div className="col-11 col-lg-5">
      <div className='bg-darkblue fs-ss-30 text-white fw-500 text-center py-2 py-lg-4'>
          Shutter Manual
        </div>
        <FAQs2/>
      </div>
    </div>
    </section>

    <section className="py-4 py-lg-5"/>

    <CTA/>

    <section className="py-4 py-lg-5"/>

    </section>

    <Footer/>
    </>
  );
}

export default AboutUs;
