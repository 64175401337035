import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom"

// Components
import Header from '../components/layout/Header';
import ProfileImg from '../assets/images/ourteam/ImgProfile.webp';
import Footer from '../components/layout/Footer';
import Img1 from '../assets/images/ourteam/Img.webp';
import Icon1 from '../assets/images/ourteam/Icon1.webp';
import Icon2 from '../assets/images/ourteam/Icon2.webp';
import Icon3 from '../assets/images/ourteam/Icon3.webp';
import Icon4 from '../assets/images/ourteam/Icon4.webp';
import BannerImage from '../assets/images/ourteam/Banner.webp';
import CommonBanner from '../components/home/CommonBanner';
import CTA from '../components/CTA';
import InquiryForm from '../components/InquiryForm';
// Sources

const OurTeam = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Our Team | Dar Tariq Company
      </title>
    </Helmet>
    <Header/>
    <CommonBanner
    BannerImage={BannerImage}
    Word1="OUR"
    Word2="TEAM"
    Para={<>
    Securing Every <br className='d-block d-md-none' /> Moment, Together
    </>}
    />
    <section className="container-fluid px-0" id="website-responsive">

    <div className="py-0 py-lg-5"/>

    <section className="container-lg px-xl-5">
      <div className="row justify-content-center align-items-center">
      <div className="col-11 col-lg-5 d-none d-lg-block px-0">
          <img src={Img1} alt="" className='w-100' />
        </div>
        <div className="col-11 col-lg-6 px-4 px-lg-5 py-5 py-lg-4 text-white">
          <div className="">
          <div className="fs-ss-30 fw-700 lh-sm text-darkblue">
            CEO Message:
            </div>
            <div className="fs-ss-30 fw-500 lh-sm text-darkblue">
            Elevating Craftsmanship, Embracing Innovation
            </div>
            <div className="py-2" />
            <div className="fs-ss-16 fw-400 text-ss-secondary">
            As we embark on a new phase, I extend my gratitude for your unwavering commitment to excellence in crafting window and door shutters. Our journey is marked by precision, innovation, and a dedication to quality that sets us apart. In the face of evolving industry dynamics, let’s embrace innovation, push boundaries, and continue to redefine standards. Together, we’ll shape a future where [Company Name] not only thrives but leads. Your talents, ideas, and passion are our greatest assets – let’s collaborate, innovate, and ensure our continued success.
            </div>
            <div className="py-2" />
            <div className="fs-ss-26 fw-700 lh-sm text-darkblue">
              Mian Tariq
            </div>
            <div className="fs-ss-16 fw-500 text-ss-secondary">
              CEO & Founder of DTC
            </div>
          </div>
        </div>
        <div className="col-11 col-lg-6 d-block d-lg-none px-0">
          <img src={Img1} alt="" className='w-100' />
        </div>
      </div>
    </section>

    <div className="py-4 py-lg-5"/>

    <section className="container-fluid bg-ourteam py-5">
    <div className="ms-lg-5 ps-lg-5 py-lg-5">
    <div className="row justify-content-center align-items-center gy-5 mx-auto ps-lg-5 text-center text-lg-start">
    <div className="col-6 col-md-3 text-white">
      <img src={Icon4} alt="" className='w-icon' />
      <div className="lh-sm fw-700 fs-ss-20 pt-3">
       <span className='fs-ss-35'> 500 </span> <br />
       <hr className='hr2' />
       <span className='fs-ss-22 fw-500'> Happy Customers </span>
      </div>
      </div>
      <div className="col-6 col-md-3 text-white">
      <img src={Icon1} alt="" className='w-icon' />
      <div className="lh-sm fw-700 fs-ss-20 pt-3">
      <span className='fs-ss-35'> 2000 </span> <br />
      <hr className='hr2' />
       <span className='fs-ss-22 fw-500'> Designs Completed </span>
      </div>      
      </div>
      <div className="col-6 col-md-3 text-white">
      <img src={Icon3} alt="" className='w-icon' />
      <div className="lh-sm fw-700 fs-ss-20 pt-3">
      <span className='fs-ss-35'> 850 </span> <br />
      <hr className='hr2' />
       <span className='fs-ss-22 fw-500'> Shutters Installed </span>
      </div>      
      </div>
      <div className="col-6 col-md-3 text-white">
      <img src={Icon2} alt="" className='w-icon' />
      <div className="lh-sm fw-700 fs-ss-20 pt-3">
      <span className='fs-ss-35'> 95 </span> <br />
      <hr className='hr2' />
       <span className='fs-ss-22 fw-500'> Awards </span>
      </div>      
      </div>
    </div>
    </div>
    </section>

    <section className="container-fluid bg-lightblue2">
    <section className="container-lg">
    <section className="py-4 py-lg-5"/>
    <div className="fs-ss-35 fw-700 lh-sm text-center pb-5">
      Our Team
    </div>
      <div className="row justify-content-center align-items-center gy-4">
      <div className="col-8 col-md-3 text-center">
      <div className="col-12 col-lg-11 mx-auto shadow-ss-1">
          <img src={ProfileImg} alt="" className='w-100' />
          <div className="fs-ss-16 fw-400 lh-base bg-white p-3">
           <span className='fw-600 fs-ss-20'>  Muhammad Asim  </span><br />
            Factory Manager
            </div>
        </div>
        </div>
        <div className="col-8 col-md-3 text-center">
        <div className="col-12 col-lg-11 mx-auto shadow-ss-1">
          <img src={ProfileImg} alt="" className='w-100' />
          <div className="fs-ss-16 fw-400 lh-base bg-white p-3">
           <span className='fw-600 fs-ss-20'>  Saeed  </span><br />
           Sales
            </div>
        </div>
        </div>
        <div className="col-8 col-md-3 text-center">
        <div className="col-12 col-lg-11 mx-auto shadow-ss-1">
          <img src={ProfileImg} alt="" className='w-100' />
          <div className="fs-ss-16 fw-400 lh-base bg-white p-3">
           <span className='fw-600 fs-ss-20'>  Shamsher  </span><br />
           Sales
            </div>
        </div>
        </div>
      </div>
      <div className='py-3 py-lg-4' />
      <div className="row justify-content-center align-items-center gy-4">
      <div className="col-8 col-md-3 text-center">
      <div className="col-12 col-lg-11 mx-auto shadow-ss-1">
          <img src={ProfileImg} alt="" className='w-100' />
          <div className="fs-ss-16 fw-400 lh-base bg-white p-3">
           <span className='fw-600 fs-ss-20'>  Mehboob  </span><br />
           Accountant
            </div>
        </div>
        </div>
        <div className="col-8 col-md-3 text-center">
        <div className="col-12 col-lg-11 mx-auto shadow-ss-1">
          <img src={ProfileImg} alt="" className='w-100' />
          <div className="fs-ss-16 fw-400 lh-base bg-white p-3">
           <span className='fw-600 fs-ss-20'>  Basha  </span><br />
           Supervisor
            </div>
        </div>
        </div>
      </div>
      <section className="py-4 py-lg-5"/>
    </section>
    </section>
    
    <section className="py-4 py-lg-5"/>

    <CTA/>

    <section className="py-4 py-lg-5"/>
  
    </section>

    <Footer/>
    </>
  );
}

export default OurTeam;
