import React, { Component } from "react";
import Slider from "react-slick";

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      className: "center",
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
      
    };
    return (
      <div className="">
        <Slider {...settings}>
        <div className="rounded-3 shadow-ss-1 p-4 my-md-5 mt-4 mb-5 w-90 bg-white ms-2">
          <div className=" fs-ss-26 fw-600 text-darkblue">
            Abu Ali
          </div>
          <div className="fs-ss-16 fw-500 pb-2">
            Propreitor
          </div>
          <div className="fs-ss-14 fw-400">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde eligendi nostrum dignissimos alias incidunt. Maiores aperiam dicta aliquam reiciendis libero atque quo quod provident quaerat facilis nihil dolores, minus rem voluptate voluptatem nam, ullam reprehenderit molestiae nostrum, minima eius veritatis.
          </div>
        </div>
        <div className="rounded-3 shadow-ss-1 p-4 my-md-5 mt-4 mb-5 w-90 bg-white ms-2">
          <div className=" fs-ss-26 fw-600 text-darkblue">
            Abu Ali
          </div>
          <div className="fs-ss-16 fw-500 pb-2">
            Propreitor
          </div>
          <div className="fs-ss-14 fw-400">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde eligendi nostrum dignissimos alias incidunt. Maiores aperiam dicta aliquam reiciendis libero atque quo quod provident quaerat facilis nihil dolores, minus rem voluptate voluptatem nam, ullam reprehenderit molestiae nostrum, minima eius veritatis.
          </div>
        </div>
        <div className="rounded-3 shadow-ss-1 p-4 my-md-5 mt-4 mb-5 w-90 bg-white ms-2">
          <div className=" fs-ss-26 fw-600 text-darkblue">
            Abu Ali
          </div>
          <div className="fs-ss-16 fw-500 pb-2">
            Propreitor
          </div>
          <div className="fs-ss-14 fw-400">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde eligendi nostrum dignissimos alias incidunt. Maiores aperiam dicta aliquam reiciendis libero atque quo quod provident quaerat facilis nihil dolores, minus rem voluptate voluptatem nam, ullam reprehenderit molestiae nostrum, minima eius veritatis.
          </div>
        </div>
        </Slider>
      </div>
    );
  }
}