import React from 'react';

const WhatsIncluded = (props  ) => {

  return (
    <>

    <section className="container-fluid text-center bg-lightblue2 py-5">
    <section className="container-lg py-lg-4">
    <div className='pb-4 pb-lg-5 text-center'>
    <div className="fs-ss-35 fw-700 lh-sm text-ss-secondary pb-3">
      What's Included
    </div>
    <div className='fs-ss-16 fw-400 pt-2'>
      {props.Para}
    </div>
    </div>
    <div className="row justify-content-evenly align-items-start gy-4">
    <div className="col-11 col-md-5 text-start d-flex align-items-start">
    <div className="me-4 ">
    <img src={props.Icon1} alt="" className='w-icon' />
    </div>
    <div className="">
    <div className="fs-ss-30 fw-700 lh-sm text-darkblue">
      Child Safety    
    </div>
    <div className='fs-ss-16 fw-400 pt-2 pe-lg-5'>
      {props.Para1}
    </div>
    </div>
    </div>

    <div className="col-11 col-md-5 text-start d-flex align-items-start">
    <div className="me-4 ">
    <img src={props.Icon2} alt="" className='w-icon' />
    </div>
    <div className="">
    <div className="fs-ss-30 fw-700 lh-sm text-darkblue">
      Product Information    
    </div>
    <div className='fs-ss-16 fw-400 pt-2 pe-lg-5'>
      {props.Para2}
    </div>
    </div>
    </div>
    </div>
    </section>
    </section>

    </>
  );
}

export default WhatsIncluded;
