import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom"

// Components
import Header from '../components/layout/Header';
import HomeBanner from '../components/home/HomeBanner';
import OurService1 from '../assets/images/home/OurService1.jpg';
import OurService2 from '../assets/images/home/OurService2.jpg';
import OurService3 from '../assets/images/home/OurService3.jpg';
import ReachUs from '../assets/images/home/ReachUs.svg';
import Testimonials from '../components/home/Testimonials';
import Footer from '../components/layout/Footer';
import RollingShutterImg1 from '../assets/images/home/RollingShutterImg1.webp';
import PortfolioHeader from '../components/portfolio/PortfolioHeader';
// Sources

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Dar Tariq Company – All kind of Aluminium Rolling Shutter & Motors
      </title>
    </Helmet>
    <Header/>
    <HomeBanner/>

    <section className="container-fluid px-0" id="website-responsive">

    <section className="py-0 py-lg-5 my-lg-2"/>

    <section className="container-lg px-xl-5">
      <div className="row justify-content-center align-items-center bg-darkblue">
      <div className="col-11 col-lg-6 d-none d-lg-block px-0">
          <img src={RollingShutterImg1} alt="" className='w-100' />
        </div>
        <div className="col-11 col-lg-6 px-4 px-lg-5 py-5 py-lg-4 text-white">
          <div className="px-xxl-5">
            <div className="fs-ss-14 fw-400">
              Dar Tariq Company 
            </div>
            <div className="fs-ss-35 fw-700 lh-sm py-3">
            Welcome to the World of Rolling Shutters!
            </div>
            <div className="fs-ss-16 fw-400 ">
            Smart, Stylish, and Sophisticated – Aluminum Windows and Doors!             
            <div className="py-2" />
            Advance your space with the perfect security, style, and convenience blend. Rolling Shutters offer more than just window coverings; they're a wise investment in your home and lifestyle. Whether you seek enhanced security measures, boosted energy efficiency, or the vitality of modern elegance, Rolling Shutters has you covered.             
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 d-block d-lg-none px-0">
          <img src={RollingShutterImg1} alt="" className='w-100' />
        </div>
      </div>
    </section>

    <div className="py-4 py-lg-5"/>

    <section className="container-fluid bg-lightblue2 pb-5 text-ss-primary">
    <section className="container-lg text-center">
      <div className="row gy-5 justify-content-center align-items-center">
        <div className="col-11 col-lg-10">
          <div className="">
            <div className="fs-ss-35 fw-700 lh-sm">
            Transform Your Home with Style and Security
            </div>
            <div className="py-2" />          
            <div className="fs-ss-16 fw-500 lh-lg">
            See the diverse range of Rolling Shutter options we offer. Join us as we offer you a transformative experience. Let's start this adventure together! We've got skilled experts to help you pick and set it up smoothly. Find the ideal fit for your space and requirements, from aluminum doors to windows.  
            <div className="py-2" />          
            Don't wait any longer. Discover the magic of Rolling Shutters to transform your space!
            </div>
          </div>
          <div className="py-2" />          
          <div className=''>
        <Link className="" to="/about-us">
        <button className='btn-darkblue text-white py-1 px-3 fs-ss-16 border-0 rounded-5 mt-3 mt-lg-0'>
          Read More
        </button>
        </Link>
    </div>
        </div>
      </div>
    </section>
    </section>
    
    <div className="py-4 py-lg-5"/>

    <section className="container-lg">
      <div className="row justify-content-center gy-5">
        <div className="col-8 col-md-3 bg-service-1 rounded-3 text-end ">
          <div className='text-decoration-none' href="/">
          <div className="py-5 my-lg-5" />
          <div className="py-5" />
          <div className="fs-ss-18 fw-600 text-white pb-2">
            Residential
          </div>
          </div>
        </div>
        <div className="col-8 col-md-3 bg-service-2 rounded-3 text-end mx-lg-4">
          <div className='text-decoration-none' href="/">          
          <div className="py-5 my-lg-5" />
          <div className="py-5" />
          <div className="fs-ss-18 fw-600 text-white pb-2">
            Commercial
          </div>
          </div>
        </div>
        <div className="col-8 col-md-3 bg-service-3 rounded-3 text-end">
          <div className='text-decoration-none' href="/">          
          <div className="py-5 my-lg-5" />
          <div className="py-5" />
          <div className="fs-ss-18 fw-600 text-white pb-2">
          Ultrasonic Cleaning
          </div>
          </div>
        </div>
      </div>
    </section>

    <div className="py-4 py-lg-5"/>

    <div className="fs-ss-35 fw-700 lh-sm text-center pb-4 pb-lg-5 text-ss-primary">
      Our Services
    </div>

    <section className="container-fluid text-ss-primary">
      <div className="row justify-content-center align-items-center text-dark border-lightblue">
      <div className="col-12 col-md-6 px-0 d-block d-lg-none">
          <img src={OurService1} alt="" className='w-100' />
        </div>
        <div className="col-11 col-md-6 p-4 py-lg-5 pt-4 pb-5 px-lg-5  ">
          <div className="px-lg-5">
            <div className="fs-ss-35 fw-700 lh-sm">
            Remote Control Shutters
            </div>
            <div className="py-2" />
            <div className="fs-ss-16 fw-400 lh-base">
            Introducing our Remote-Control Shutters, the personification of convenience and security. The shutters are perfect for doors, windows, and gates, with custom sizing, safety, and protection from fire and theft. Enjoy peace of mind with our advanced rolling shutter technology.
            </div>
            <div className='pt-3'>
        <Link className="" to="/automatic-remote-rolling-shutter">
        <button className='btn-darkblue text-white py-1 px-3 fs-ss-16 border-0 rounded-5 mt-3 mt-lg-0'>
          Read More
        </button>
        </Link>
    </div>
          </div>
        </div>
        <div className="col-12 col-md-6 px-0 d-none d-lg-block">
          <img src={OurService1} alt="" className='w-100' />
        </div>
      </div>
    </section>

    <section className="container-fluid text-ss-primary">
      <div className="row justify-content-center align-items-center text-dark">
        <div className="col-12 col-md-6 px-0">
          <img src={OurService2} alt="" className='w-100' />
        </div>
        <div className="col-11 col-md-6 p-4 py-lg-5 pt-4 pb-5 px-lg-5  ">
          <div className="px-lg-5">
            <div className="fs-ss-35 fw-700 lh-sm">
            Manual Rolling Shutters
            </div>
            <div className="py-2" />
            <div className="fs-ss-16 fw-400 lh-base">
            Experience top-notch security with our Manual Rolling Shutters. Designed meticulously for doors, windows, and gates, they offer unmatched protection. Rely on our quality shutters. Make the switch to our Manual Rolling Shutters now!
            </div>
            <div className='pt-3'>
        <Link className="" to="/manual-rolling-shutter">
        <button className='btn-darkblue text-white py-1 px-3 fs-ss-16 border-0 rounded-5 mt-3 mt-lg-0'>
          Read More
        </button>
        </Link>
    </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid text-ss-primary">
      <div className="row justify-content-center align-items-center text-dark border-lightblue">
      <div className="col-12 col-md-6 px-0 d-block d-lg-none">
          <img src={OurService3} alt="" className='w-100' />
        </div>
        <div className="col-11 col-md-6 p-4 py-lg-5 pt-4 pb-5 px-lg-5  ">
          <div className="px-lg-5">
            <div className="fs-ss-35 fw-700 lh-sm">
            Shutters Accessories
            </div>
            <div className="py-2" />
            <div className="fs-ss-16 fw-400 lh-base">
            Upgrade your security with our premium Shutter Accessories. Our accessories boost the style and function of Automatic Rolling Shutters. They offer peace of mind. Check out our selection now!
            </div>
            <div className='pt-3'>
        <Link className="" to="/shutter-accessories">
        <button className='btn-darkblue text-white py-1 px-3 fs-ss-16 border-0 rounded-5 mt-3 mt-lg-0'>
          Read More
        </button>
        </Link>
    </div>
          </div>
        </div>
        <div className="col-12 col-md-6 px-0 d-none d-lg-block">
          <img src={OurService3} alt="" className='w-100' />
        </div>
      </div>
    </section>

    <section className="py-4 py-lg-5"/>
    
    <section className="container-lg text-ss-primary ">
    <div className="fs-ss-35 fw-700 lh-sm text-center pb-4 pb-lg-5 mb-lg-5">
      Reach Us
    </div>
    <img src={ReachUs} alt="" className='w-100 px-lg-5' />
    </section>

    <section className="py-4 py-lg-5"/>

    <section className="container-fluid bg-lightblue2 py-5 text-ss-primary">
    <section className="container-lg">
    <div className="fs-ss-35 fw-700 lh-sm text-center text-darkblue">
      Our Projects
    </div>
    <PortfolioHeader/>
    </section>
    </section>

    <section className="container-fluid pb-4 mb-md-3 text-ss-primary d-block d-md-none d-lg-block">
      <div className="row justify-content-center">
      <div className="col-11 col-md-3 align-self-center bg-slide-2 pt-5 py-lg-5">
      <div className="col-md-8 mx-md-auto py-lg-5">
        <div className="fs-ss-35 fw-600 lh-sm position-relative text-start">
          Client <br className="d-none d-md-block" /> Testimonials
        </div>
      </div>
        </div>
        <div className="col-12 col-md-8 pt-lg-5 ">
        <Testimonials/>
        </div>
      </div>
    </section>
    </section>

    <Footer/>
    </>
  );
}

export default Home;
