import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom"

// Components
import Header from '../components/layout/Header';
import HomeBanner from '../components/home/HomeBanner';
import OurService1 from '../assets/images/home/OurService1.jpg';
import OurService2 from '../assets/images/home/OurService2.jpg';
import OurService3 from '../assets/images/home/OurService3.jpg';
import ReachUs from '../assets/images/home/ReachUs.svg';
import Testimonials from '../components/home/Testimonials';
import Footer from '../components/layout/Footer';
import RollingShutterImg1 from '../assets/images/home/RollingShutterImg1.webp';
import BannerImage from '../assets/images/ourservices/Banner.webp';
import CommonBanner from '../components/home/CommonBanner';
import CTA from '../components/CTA';
import InquiryForm from '../components/InquiryForm';
import InquiryForm2 from '../components/InquiryForm2';
// Sources

const OurServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Our Services | Dar Tariq Company
      </title>
    </Helmet>
    <Header/>
    <CommonBanner
    BannerImage={BannerImage}
    Word1="OUR"
    Word2={<> 
    <div className='d-lg-inline'> SERVICES </div>
    </>}
    Para={<>
    Transforming Spaces, <br className='d-block d-md-none' /> Securing Futures
    </>}
    />
    <section className="container-fluid px-0" id="website-responsive">

    <div className="py-4 py-lg-5"/>

    <div className="fs-ss-30 fw-700 lh-sm text-center pb-5 text-ss-primary px-2">
      We offer a wide range of shutter <br className='' /> services, including:
    </div>
    <section className="container-lg">
      <div className="row justify-content-center gy-5">
        <div className="col-8 col-md-3 bg-service-1 rounded-3 text-end ">
          <div className='text-decoration-none' href="/">
          <div className="py-5 my-lg-5" />
          <div className="py-5" />
          <div className="fs-ss-18 fw-500 text-white pb-2 text-uppercase lh-sm ">
          Installation of <br /> new shutters
          </div>
          </div>
        </div>
        <div className="col-8 col-md-3 bg-service-2 rounded-3 text-end mx-lg-4">
          <div className='text-decoration-none' href="/">          
          <div className="py-5 my-lg-5" />
          <div className="py-5" />
          <div className="fs-ss-18 fw-500 text-white pb-2 text-uppercase lh-sm">
          Repair of <br /> existing shutters
          </div>
          </div>
        </div>
        <div className="col-8 col-md-3 bg-service-3 rounded-3 text-end">
          <div className='text-decoration-none' href="/">          
          <div className="py-5 my-lg-5" />
          <div className="py-5" />
          <div className="fs-ss-18 fw-500 text-white pb-2 text-uppercase lh-sm ">
          Motorization of <br /> shutters
          </div>
          </div>
        </div>
      </div>
    </section>

    <div className="py-3 py-lg-4"/>

    <div className='fs-ss-18 fw-500 pt-2 text-center px-3'>
    In addition to this we offer wide range of Remote Control Roller & 
    <br className='d-none d-lg-block' /> Manual Push Pull Shutters
    </div>

    <div className="py-3 py-lg-4"/>

    <section className="container-fluid text-ss-primary">
      <div className="row justify-content-center align-items-center text-dark border-lightblue">
      <div className="col-12 col-md-6 px-0 d-block d-lg-none">
          <img src={OurService1} alt="" className='w-100' />
        </div>
        <div className="col-11 col-md-6 p-4 py-lg-5 pt-4 pb-5 px-lg-5  ">
          <div className="px-lg-5">
            <div className="fs-ss-35 fw-700 lh-sm">
            Remote Control Shutters
            </div>
            <div className="py-2" />
            <div className="fs-ss-16 fw-400 lh-base">
            Introducing our Remote-Control Shutters, the personification of convenience and security. The shutters are perfect for doors, windows, and gates, with custom sizing, safety, and protection from fire and theft. Enjoy peace of mind with our advanced rolling shutter technology.
            </div>
            <div className='pt-3'>
        <Link className="" to="/automatic-remote-rolling-shutter">
        <button className='btn-darkblue text-white py-1 px-3 fs-ss-16 border-0 rounded-5 mt-3 mt-lg-0'>
          Read More
        </button>
        </Link>
    </div>
          </div>
        </div>
        <div className="col-12 col-md-6 px-0 d-none d-lg-block">
          <img src={OurService1} alt="" className='w-100' />
        </div>
      </div>
    </section>

    <section className="container-fluid text-ss-primary">
      <div className="row justify-content-center align-items-center text-dark">
        <div className="col-12 col-md-6 px-0">
          <img src={OurService2} alt="" className='w-100' />
        </div>
        <div className="col-11 col-md-6 p-4 py-lg-5 pt-4 pb-5 px-lg-5  ">
          <div className="px-lg-5">
            <div className="fs-ss-35 fw-700 lh-sm">
            Manual Rolling Shutters
            </div>
            <div className="py-2" />
            <div className="fs-ss-16 fw-400 lh-base">
            Experience top-notch security with our Manual Rolling Shutters. Designed meticulously for doors, windows, and gates, they offer unmatched protection. Rely on our quality shutters. Make the switch to our Manual Rolling Shutters now!
            </div>
            <div className='pt-3'>
        <Link className="" to="/manual-rolling-shutter">
        <button className='btn-darkblue text-white py-1 px-3 fs-ss-16 border-0 rounded-5 mt-3 mt-lg-0'>
          Read More
        </button>
        </Link>
    </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid text-ss-primary">
      <div className="row justify-content-center align-items-center text-dark border-lightblue">
      <div className="col-12 col-md-6 px-0 d-block d-lg-none">
          <img src={OurService3} alt="" className='w-100' />
        </div>
        <div className="col-11 col-md-6 p-4 py-lg-5 pt-4 pb-5 px-lg-5  ">
          <div className="px-lg-5">
            <div className="fs-ss-35 fw-700 lh-sm">
            Shutters Accessories
            </div>
            <div className="py-2" />
            <div className="fs-ss-16 fw-400 lh-base">
            Upgrade your security with our premium Shutter Accessories. Our accessories boost the style and function of Automatic Rolling Shutters. They offer peace of mind. Check out our selection now!
            </div>
            <div className='pt-3'>
        <Link className="" to="/shutter-accessories">
        <button className='btn-darkblue text-white py-1 px-3 fs-ss-16 border-0 rounded-5 mt-3 mt-lg-0'>
          Read More
        </button>
        </Link>
    </div>
          </div>
        </div>
        <div className="col-12 col-md-6 px-0 d-none d-lg-block">
          <img src={OurService3} alt="" className='w-100' />
        </div>
      </div>
    </section>
    <section className="container-fluid bg-contact">
      <div className="row justify-content-end">
        <div className="col-12 col-lg-5 me-lg-5 py-3">
          <InquiryForm2/>
        </div>
      </div>
    </section>
    <section className="py-4 py-lg-5"/>

    <CTA/>

    <section className="py-4 py-lg-5"/>
  
    </section>

    <Footer/>
    </>
  );
}

export default OurServices;
