import React from 'react';
import { TelephoneFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
 
const CTA = () => {
  return (
    <>
      <section className="container-lg">
      <div className="row justify-content-center">
        <div className="col-11 col-lg-9 bg-cta text-center fw-700 fs-ss-40 text-ss-secondary py-5 lh-sm" style={{borderRadius:"200px"}}>
          <div className="px-3">
            Need appointment to help? <br className='d-none d-lg-block' />
            Call us to know more
          </div>
          <div className='py-0' />
        <button className='bg-white-grey text-ss-secondary py-2 px-3 fs-ss-16 border-0 fw-500 rounded-5 mt-3 mt-lg-0'>
        <TelephoneFill className='me-1'/> <a className='text-decoration-none text-ss-secondary' href='tel:(965) 966-77319' target='_blank'> (965) 966-77319 </a> 
        </button>
        </div>
      </div>
    </section>
    </>
  );
}

export default CTA;
