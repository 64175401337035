import React from 'react';
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom";

const NavBarLink = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
    <li className="nav-item pt-1 fw-400">
    <Link className={splitLocation[1] === `${props.Link_To}` ? `nav-link fw-400 text-ss-secondary text-darkblue ${props.Border}` : `nav-link text-ss-secondary fw-400   ${props.Border}`} to={`/${props.Link_To}`}> {props.Name} </Link>
    </li>
    </>
  )
}

export default NavBarLink